import React from "react"
import PropTypes from "prop-types"

// Components
import { Link, graphql } from "gatsby"
import { Typography, Container } from "@mui/material"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticleList from "../components/article-list"

const CategoryPage = ({ pageContext, data }) => {
  const { category } = pageContext
  const { edges, totalCount } = data.allMdx
  const CategoryHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } in ${category}`

  return (
    <Layout>
    <SEO title="Articles" />
    <Container>
    <Typography component="h1" variant="h2" gutterBottom>{CategoryHeader}</Typography>
      <ArticleList posts={data} />
    </Container>
    </Layout>
  )
}

CategoryPage.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default CategoryPage

export const pageQuery = graphql`
  query($category: String) {
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___lastUpdatedDate], order: DESC }
      filter: { frontmatter: { categories: { in: [$category] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            lastUpdatedDate(formatString: "MMM DD, YYYY")
            categories
            tags
            featuredImage {
                childImageSharp {
                  gatsbyImageData
                }
              }
          }
          id
          slug
          excerpt
          timeToRead
        }
      }
    }
  }
`